import { useState } from "react";
import Button from "../UI/Button";
import Yearpicker from "../UI/Yearpicker";
import styles from "./SettingsScreen.module.css";
import { useEffect } from "react";
import ToggleButton from "../UI/ToggleButton";
import LoadingSpinner from "../UI/LoadingSpinner";
import { useContext } from "react";
import AuthContext from "../../store/auth-context";

const SettingsScreen = () => {
  // const [enteredEmail, setEnteredEmail] = useState("");
  // const [enteredBirthday, setEnteredBirthday] = useState("");
  // const [enteredMobile, setEnteredMobile] = useState("");
  // const [enteredRole, setEnteredRole] = useState("");
  const [enteredGender, setEnteredGender] = useState("");
  const [enteredCountry, setEnteredCountry] = useState("");
  const [selectedYear, setSelectedYear] = useState();
  const [enteredHeight, setEnteredHeight] = useState("");
  const [enteredWeight, setEnteredWeight] = useState("");
  const [profDriver, setProfDriver] = useState("");
  const [profLicense, setProfLicense] = useState("");
  const [enteredOrg, setEnteredOrg] = useState("");
  // const [enteredVehicle, setEnteredVehicle] = useState("");
  // const [enteredVehNum, setEnteredVehNum] = useState("");
  // const [enteredLifestyle, setEnteredLifestyle] = useState("");
  const [enteredCurPassword, setEnteredCurPassword] = useState("");
  const [enteredNewPassword, setEnteredNewPassword] = useState("");
  const [enteredConfNewPassword, setEnteredConfNewPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  //to run only once at the begining.
  useEffect(() => {
    if (global.loggedInUserRole === "Driver") {
      setIsLoading(true);
    }

    const participartGetUrl =
      "https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaParticipantProfile-" +
      global.loggedInUserName;
    fetch(participartGetUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        data.realtime.results.bindings[0].yearOfBirth
          ? setSelectedYear(data.realtime.results.bindings[0].yearOfBirth.value)
          : setSelectedYear(null);
        data.realtime.results.bindings[0].gender
          ? setEnteredGender(data.realtime.results.bindings[0].gender.value)
          : setEnteredGender(null);
        data.realtime.results.bindings[0].height
          ? setEnteredHeight(data.realtime.results.bindings[0].height.value)
          : setEnteredHeight(null);
        data.realtime.results.bindings[0].weight
          ? setEnteredWeight(data.realtime.results.bindings[0].weight.value)
          : setEnteredWeight(null);
        data.realtime.results.bindings[0].country
          ? setEnteredCountry(data.realtime.results.bindings[0].country.value)
          : setEnteredCountry(null);
        data.realtime.results.bindings[0].companyID
          ? setEnteredOrg(data.realtime.results.bindings[0].companyID.value)
          : setEnteredOrg(null);
        data.realtime.results.bindings[0].yearsAsProfessionalDriverRider
          ? setProfDriver(
              data.realtime.results.bindings[0].yearsAsProfessionalDriverRider
                .value
            )
          : setProfDriver(null);
        data.realtime.results.bindings[0].yearsSinceHavingDrivingLicence
          ? setProfLicense(
              data.realtime.results.bindings[0].yearsSinceHavingDrivingLicence
                .value
            )
          : setProfLicense(null);
        data.realtime.results.bindings[0].participantType
          ? data.realtime.results.bindings[0].participantType.value === "1"
            ? setIsChecked(true)
            : setIsChecked(false)
          : setIsChecked(false);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  const submitHandler = async () => {
    const id = global.loggedInUserName;
    const updateProfileUrl = `https://iot-app.snap4city.org/orion-broker-panacea/v2/entities/PanaceaParticipantProfile-${id}/attrs?type=Sensor&elementid=PanaceaParticipantProfile-${id}`;
    //call for updating
    const jsonObj = {
      role: { value: global.loggedInUserRole },
      country: { value: enteredCountry },
      yearOfBirth: { value: selectedYear },
      companyID: { value: enteredOrg },
      gender: { value: enteredGender },
      height: { value: enteredHeight },
      weight: { value: enteredWeight },
      yearsAsProfessionalDriverRider: { value: profDriver },
      yearsSinceHavingDrivingLicence: { value: profLicense },
      nickname: { value: id },
      participantType: { value: isChecked ? 1 : 0 }, //if shareInfo= 0 not sharing
    };
    const stringObj = JSON.stringify(jsonObj);

    fetch(updateProfileUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "PATCH",
      body: stringObj,
    })
      .then((response) => {
        console.log("UPDATE");
        if (response.status === 204) {
          alert("Successfully updated profile.");
        }
      })
      .catch((err) => {
        alert("Error. Profile could not be updated.");
        console.log(err.message);
      });
  };

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <div className={styles.innerContainer}>
          {/* must be updated each time */}
          <p className="fontSize:16px">Web version: 4.5.4V</p>
          <img
            className={styles.profileImg}
            src={require("../../images/profile_picture.png")}
            alt="Profile"
          />
          {/* <div className={styles.control}>
          <label htmlFor="email">Email</label>
          <input
            type="text"
            id="name"
            value={enteredEmail}
            onChange={(e) => setEnteredEmail(e.target.value)}
          />
        </div> */}
          <div className={styles.control}>
            <label htmlFor="date">Year of Birth</label>
            <Yearpicker
              selected={selectedYear}
              onYearChange={handleYearChange}
            />
          </div>

          <div className={styles.control}>
            <label htmlFor="gender">Gender</label>
            <select
              defaultValue=""
              value={enteredGender}
              onChange={(e) => setEnteredGender(e.target.value)}
            >
              <option value="" disabled></option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className={styles.control}>
            <label htmlFor="height">Height (in cm)</label>
            <input
              type="number"
              id="height"
              value={enteredHeight}
              onChange={(e) => setEnteredHeight(e.target.value)}
            />
          </div>
          <div className={styles.control}>
            <label htmlFor="weight">Weight (in kg)</label>
            <input
              type="number"
              id="weight"
              value={enteredWeight}
              onChange={(e) => setEnteredWeight(e.target.value)}
            />
          </div>
          <div className={styles.control}>
            <label htmlFor="country">Country</label>
            <select
              defaultValue=""
              value={enteredCountry}
              onChange={(e) => setEnteredCountry(e.target.value)}
            >
              <option value="" disabled></option>
              <option value="Greece">Greece</option>
              <option value="Spain">Spain</option>
              <option value="Sweden">Sweden</option>
            </select>
          </div>
          {/* <div className={styles.control}>
        <label htmlFor="role">Role</label>
        <select
          defaultValue=""
          value={enteredRole}
          onChange={(e) => setEnteredRole(e.target.value)}
        >
          <option value="" disabled></option>
          <option value="driver">Driver</option>
          <option value="rider">Rider</option>
          <option value="operator">Operator</option>
          <option value="admin">Administrator</option>
          <option value="enfrocer">Enforcer</option>
          <option value="ccsspecialist">Countermeasure Specialist</option>
        </select>
      </div> */}

          {/* <div className={styles.control}>
          <label htmlFor="number">Mobile number</label>
          <input
            type="number"
            id="number"
            value={enteredMobile}
            onChange={(e) => setEnteredMobile(e.target.value)}
          />
        </div> */}
          <div className={styles.control}>
            <label htmlFor="organization">Organization</label>
            <input
              type="text"
              id="organization"
              value={enteredOrg}
              onChange={(e) => setEnteredOrg(e.target.value)}
            />
          </div>
          <div className={styles.control}>
            <label htmlFor="yearsAsDriver">Years as Prof.Driver/Rider</label>
            <input
              type="number"
              id="yearsAsDriver"
              value={profDriver}
              onChange={(e) => setProfDriver(e.target.value)}
            />
          </div>
          <div className={styles.control}>
            <label htmlFor="yearsOfLicense">
              Year of having Prof. Driver Licence
            </label>
            <input
              type="number"
              id="yearsOfLicense"
              value={profLicense}
              onChange={(e) => setProfLicense(e.target.value)}
            />
          </div>
          {/* <div className={styles.control}>
        <label htmlFor="vehicle">Vehicle Category</label>
        <input
          type="text"
          id="vehicle"
          value={enteredVehicle}
          onChange={(e) => setEnteredVehicle(e.target.value)}
        />
      </div> */}
          {/* <div className={styles.control}>
        <label htmlFor="vehicle_num">Vehicle plate</label>
        <input
          type="text"
          id="vehicle_num"
          value={enteredVehNum}
          onChange={(e) => setEnteredVehNum(e.target.value)}
        />
      </div> */}
          {/* <div className={styles.control}>
          <label htmlFor="lifestyle">Lifestyle</label>
          <input
            type="text"
            id="lifestyle"
            value={enteredLifestyle}
            onChange={(e) => setEnteredLifestyle(e.target.value)}
          />
        </div> */}
          {global.loggedInUserRole === "Driver" && (
            <div className={styles.control}>
              <label htmlFor="shareInfo">
                Share Panacea Information/ Results with your Operator:
              </label>
              <ToggleButton isChecked={isChecked} onToggle={handleToggle} />
            </div>
          )}
          <div className={styles.buttons}>
            <Button onClick={submitHandler}>
              {" "}
              <p style={{ fontSize: 20 }}>Save changes</p>
            </Button>
          </div>
          <div className={styles.control}>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={enteredCurPassword}
              onChange={(e) => setEnteredCurPassword(e.target.value)}
            />
          </div>
          <div className={styles.control}>
            <label htmlFor="new_password">New password</label>
            <input
              type="password"
              id="new_password"
              value={enteredNewPassword}
              onChange={(e) => setEnteredNewPassword(e.target.value)}
            />
          </div>
          <div className={styles.control}>
            <label htmlFor="retype">Retype new password</label>
            <input
              type="password"
              id="retype"
              value={enteredConfNewPassword}
              onChange={(e) => setEnteredConfNewPassword(e.target.value)}
            />
          </div>
          <div className={styles.buttons}>
            <Button onClick={submitHandler}>
              <p style={{ fontSize: 20 }}>Save changes</p>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsScreen;
