import ProfileCard from "../UI/ProfileCard";
import styles from "./MyProfileScreen.module.css";
import ProgressTopBar from "../UI/ProgressTopBar";
import { useState, useEffect } from "react";
import LoadingSpinner from "../UI/LoadingSpinner";
import AuthContext from "../../store/auth-context";
import { useContext } from "react";

const MyProfileScreen = ({ name }) => {
  const [shift, setShift] = useState();
  const [fatigue, setFatigue] = useState("0");
  const [fatigueDecision, setFatigueDecision] = useState("No Measurement Yet");
  const [stress, setStress] = useState("0");
  const [stressDecision, setStressDecision] = useState("No Measurement Yet")
  const [alcoholState, setAlcoholState] = useState("-1");
  const [alcoholDecision, setAlcoholDecision] = useState("No Measurement Yet");
  const authCtx= useContext(AuthContext)
  const token= authCtx.token

  const [isLoading, setIsLoading] = useState(false);

  
  useEffect(() => {
    const getInfo = async () => {
      setIsLoading(true);
      let getShiftUrl;
      let getAlcoholUrl;
      let getFatiguelUrl;
      let getStressUrl;
      // let prevDate= new Date(new Date().getTime()-24 * 60 * 60 * 1000).toISOString().slice(0,19) //prev date
      // let curDate=new Date(new Date().getTime()).toISOString().slice(0,19)//current date
      let fromDate=new Date(new Date().getTime()).toISOString().slice(0,11)+"00:30:00" //from date search
      let toDate=new Date(new Date().getTime()).toISOString().slice(0,11)+"23:30:00" // to date search

      let date=new Date(new Date().getTime()+24 * 60 * 60 * 1000).toISOString().slice(0,19) //current date shift
      let sevenDaysAgo= new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().slice(0,19); // 7 days ago

      let decision;


      const decisionFatigueFunction=(input)=>{
        if(input==='1' || input==='2'|| input==='3'){
          decision='Fit to Drive'
        }else if(input==='4'){
          decision='Not recommended to Drive'
        }else if(input==='5'){
          decision="Not fit to Drive"
        }else{
          console.log("INPUT==>",input)
        }
      }

      const decisionStressFunction=(input)=>{
        if(input==='1' || input==='2'|| input==='3' || input==='4'){
          decision='Fit to Drive'
        }else if(input==='5'){
          decision='Not recommended to Drive'
        }else{
          console.log("INPUT==>",input)
        }
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // You may need to adjust the content type
      };
      if (global.loggedInUserRole === "Driver") {
        // getShiftUrl =
        //   "https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaShift-" +
        //   global.loggedInUserName;
        getShiftUrl = `https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaShift-${global.loggedInUserName}&fromTime=${sevenDaysAgo}&toTime=${date}`;
        getFatiguelUrl = `https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaFatigueLevel-${global.loggedInUserName}&fromTime=${fromDate}&toTime=${toDate}`;
        getStressUrl = `https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaStressLevel-${global.loggedInUserName}&fromTime=${fromDate}&toTime=${toDate}`;
        getAlcoholUrl = `https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaAlcoholProfile-${global.loggedInUserName}&fromTime=${fromDate}&toTime=${toDate}`;
      } else {
        // getShiftUrl =
        //   "https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaShift-" +
        //   name;
        getShiftUrl = `https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaShift-${name}&fromTime=${sevenDaysAgo}&toTime=${date}`;
        getFatiguelUrl = `https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaFatigueLevel-${name}&fromTime=${fromDate}&toTime=${toDate}`;
        getStressUrl = `https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaStressLevel-${name}&fromTime=${fromDate}&toTime=${toDate}`;
        getAlcoholUrl = `https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaAlcoholProfile-${name}&fromTime=${fromDate}&toTime=${toDate}`;
      }
      const urls = [getShiftUrl,getFatiguelUrl,getStressUrl,getAlcoholUrl];

      try {
        // Use Promise.all to fetch data from all URLs concurrently
        const responses = await Promise.all(urls.map((url) => fetch(url, {headers})));

        // Process responses
        const data = await Promise.all(
          responses.map(async (response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error(`HTTP error! Status: ${response.status}`);
          })
        );

        // Now, 'data' contains an array of results from the three requests
        if (data && data.length===4) {
          console.log("DATA==>", data)
          if (data[0]?.realtime?.results?.bindings) {
            for(let i = 0; i < data[0].realtime.results.bindings.length; i++) {
              if(data[0].realtime.results.bindings[i].onDutyStarted.value<new Date().toISOString() && new Date().toISOString()<data[0].realtime.results.bindings[i].onDutyFinished.value){
                // console.log('CURR=>', new Date().toISOString())
                // console.log('DUTY=>', data[0].realtime.results.bindings[i].onDutyStarted.value)
                // console.log('FINISH=>',data[0].realtime.results.bindings[i].onDutyFinished.value )
                setShift(data[0].realtime.results.bindings[i].isOnDuty.value);
                break; //* to leave the for loop after finding the latest input
              }
            }
          }
          if (data[1]?.realtime?.results?.bindings) {
            console.log("FAT",data[1].realtime.results.bindings[0].fatigueValue.value)
            setFatigue(data[1].realtime.results.bindings[0].fatigueValue.value)
            decisionFatigueFunction(data[1].realtime.results.bindings[0].fatigueValue.value)
            setFatigueDecision(decision)
          }else{
            setFatigue("0")
            setFatigueDecision("No Measurement Yet")
          }
          if (data[2]?.realtime?.results?.bindings) {
            console.log("STR",data[2].realtime.results.bindings[0].stressLevel.value)
            setStress(data[2].realtime.results.bindings[0].stressLevel.value)
            decisionStressFunction(data[2].realtime.results.bindings[0].stressLevel.value)
            setStressDecision(decision)
          }else{
            setStress("0")
            setStressDecision("No Measurement Yet")
          }
          if (data[3]?.realtime?.results?.bindings[0]) {
            console.log("ALC",data[3].realtime.results.bindings[0].BrAC.value)
            setAlcoholState(data[3].realtime.results.bindings[0].BrAC.value);
            setAlcoholDecision(
              data[3].realtime.results.bindings[0].alcoholStatus.value
            );
          }else{
            setAlcoholState("N/A")
            setAlcoholDecision("No Measurement Yet")
          }
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setIsLoading(false);
        // Handle the error gracefully (e.g., set isLoading to false or display an error message)
      }
    };
    getInfo();
  }, [name,token]);

  

  global.driverShift = shift;
  let content;
  let alcDes;

  //for alcohol desicion
  if(alcoholState){
    if(0<=alcoholState && alcoholState<=0.1){
      alcDes ="pass"
    }else if(alcoholState>0.1){
      alcDes ="fail"
    }else{
      alcDes ="No Measurement Yet"
    }
  }


  if (shift === "1") {
    content = "On Duty";
  } else if (shift === "0") {
    content = "Off Duty";
  } else if (shift === "2") {
    content = "Pre Driving";
  } else if (shift === "3") {
    content = "On Site";
  } else if (shift === "4") {
    content = "On Lunch";
  } else {
    content = null;
  }

  console.log('SHIFT=>', content);

  return (
    <>
      {isLoading && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && (
        <>
          <div
            className={styles.shiftContainer}
            style={{
              backgroundColor:
                shift === "1"
                  ? "#3ab54a"
                  : shift === "0"
                  ? "#f32929"
                  : shift === "2"
                  ? "#df7229"
                  : shift === "3"
                  ? "#3a98b5"
                  : shift === "4"
                  ? "#b53ab5"
                  : "transparent",
            }}
          >
            <p className={styles.shiftTxt}>{content}</p>
          </div>
          <ProgressTopBar />
          <div className={styles.container}>
            {/* component for system presentation */}
            <ProfileCard
              title="Fatigue System"
              onSystem={true}
              value={fatigue}
              decision={fatigueDecision}
            />
            <ProfileCard
              title="Stress System"
              onSystem={true}
              value={stress}
              decision={stressDecision}
            />
            <ProfileCard
              title="Alcohol System"
              onSystem={true}
              value={alcoholState}
              decision={alcDes}
            />
          </div>
        </>
      )}
    </>
  );
};

export default MyProfileScreen;
